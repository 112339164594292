import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Pagination, Autoplay } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

export default class extends Controller {
  static targets = ["swiper", "pagination"]

  connect() {
    this.initSwiper()
  }

  initSwiper() {
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [Pagination, Autoplay],
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        renderBullet: (index, className) => {
          return `<span class="${className}" data-index="${index}"></span>`
        },
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: true,
      },
      on: {
        autoplayTimeLeft: (swiper, time, progress) => {
          this.updatePaginationStyles(swiper, progress)
        },
        reachEnd: () => {
          this.swiper.autoplay.stop()
        },
        autoplayStop: () => {
          this.updateActivePagination(this.swiper)
        },
        slideChange: () => {
          this.updateActivePagination(this.swiper)
        },
      },
    })
  }

  updatePaginationStyles(swiper, progress) {
    const bullets = swiper.pagination.bullets
    const activeIndex = swiper.activeIndex

    bullets.forEach((bullet, index) => {
      if (index < activeIndex) {
        bullet.style.background = "var(--color-blue-900)"
      } else if (index === activeIndex) {
        const p = `${(1 - progress) * 100}%`
        const cssStyle = `linear-gradient(90deg, var(--color-blue-900) ${p}, var(--color-gray-200) ${p})`
        bullet.style.background = cssStyle
      } else {
        bullet.style.background = "var(--color-gray-200)"
      }
    })
  }

  updateActivePagination(swiper) {
    const bullets = swiper.pagination.bullets
    const activeIndex = swiper.activeIndex

    bullets.forEach((bullet, index) => {
      if (index < activeIndex) {
        bullet.style.background = "var(--color-blue-900)" // Left of active bullet
      } else if (index === activeIndex) {
        bullet.style.background = "var(--color-blue-900)" // Active bullet
      } else {
        bullet.style.background = "var(--color-gray-200)" // Right of active bullet
      }
    })
  }
}
