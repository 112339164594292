import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["circle"]
  static values = {
    shouldAnimate: { type: Boolean },
  }

  connect() {
    if (this.shouldAnimateValue) {
      this.circleTarget.classList.add("confetti-animation--animates")
      this.circleTarget.classList.remove("hidden")
    }
  }
}
