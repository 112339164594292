import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../../tailwind.config.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "canvas",
    "axisLabels",
    "noResultHint",
    "rightMetric",
    "timeRange",
    "leftLabel",
    "rightLabel",
  ]

  static values = {
    dataUrl: String,
    timeRange: { type: String, default: "week" },
    leftMetric: String,
  }

  connect() {
    this.chart = new Chart(this.canvasTarget, {
      type: "line",
      data: this.chartData,
      options: this.chartOptions,
    })
  }

  disconnect() {
    this.chart.destroy()
  }

  selectTimeRange({ detail }) {
    this.timeRangeValue = detail.timeRange
  }

  timeRangeValueChanged() {
    this.render()
  }

  // actions
  async render() {
    const url = new URL(this.dataUrlValue)
    url.search = new URLSearchParams({
      habit: this.leftMetricValue,
      migraine: this.rightMetricTarget.value,
      time_range: this.timeRangeValue,
    }).toString()

    const response = await get(url.toString())
    if (response.ok) {
      const data = await response.json
      this.updateChart(data)
    }
  }

  updateChart({ labels, habit, migraine }) {
    const hasNoValues = [habit, migraine].every((dataset) => {
      return dataset == null || dataset.data.every((item) => item === null)
    })

    this.canvasTarget.classList.toggle("!invisible", hasNoValues)
    this.axisLabelsTarget.classList.toggle("hidden", hasNoValues)
    this.noResultHintTarget.classList.toggle("hidden", !hasNoValues)

    this.chart.data.labels = labels
    this.updateDataset(1, "y1", migraine)

    if (habit) {
      this.updateDataset(0, "y", habit)
      this.leftLabelTarget.textContent = habit.label
    }
    this.rightLabelTarget.textContent = migraine.label

    this.chart.update()
  }

  updateDataset(index, axis, dataset) {
    this.chart.data.datasets[index].data = dataset.data
    this.chart.data.datasets[index].label = dataset.label
    this.chart.options.scales[axis].min = dataset.min
    this.chart.options.scales[axis].max = dataset.max
    const ticks = { stepSize: 1 }
    if (dataset.scale === "emojis") ticks.callback = this.emojiScale
    this.chart.options.scales[axis].ticks = ticks
  }

  emojiScale(value) {
    return { 0: "😡", 1: "🙁", 2: "😐", 3: "🙂", 4: "😁" }[value]
  }

  // private

  get chartData() {
    const colorLeft = resolveConfig(tailwindConfig).theme.colors.lavender["400"]
    const colorRight = resolveConfig(tailwindConfig).theme.colors.orange["400"]

    return {
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          yAxisID: "y",
          borderColor: colorLeft,
          borderWidth: 1,
          tension: 0.25,
          pointRadius: 2,
          pointBackgroundColor: colorLeft,
        },
        {
          label: "",
          type: "bar",
          lineTension: 0.4,
          data: [],
          yAxisID: "y1",
          backgroundColor: colorRight,
          borderRadius: 3,
        },
      ],
    }
  }

  get chartOptions() {
    const options = {
      clip: false,
      spanGaps: true,
      layout: {
        padding: 5,
      },
      scales: {
        x: {
          grid: {
            drawOnChartArea: false,
          },
          ticks: {
            maxRotation: 0,
          },
        },
        y: {
          type: "linear",
          display: true,
          position: "left",
          grid: {
            drawOnChartArea: false,
          },
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    }

    if (this.leftMetricValue.length === 0) {
      options.scales.y.display = false
    }
    return options
  }
}
