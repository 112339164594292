import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "button",
    "card",
    "cardsFrame",
    "noFavoritesHint",
    "categoryContainer",
  ]

  static values = {
    activeCategory: {
      type: String,
      default: "Programme",
    },
    localStorageKey: { type: String, default: "discover-categories:active" },
  }

  connect() {
    if (this.tabFromLocalStorage) {
      this.activeCategoryValue = this.tabFromLocalStorage
    }
  }

  likedCount = 0
  lastCheckedCategory = null

  change(event) {
    localStorage.setItem(this.localStorageKeyValue, event.params.category)

    if (event.currentTarget.checked) {
      this.activeCategoryValue = event.params.category
      this.lastCheckedCategory = event.params.category
    } else {
      event.currentTarget.checked = true
    }
  }

  activeCategoryValueChanged() {
    this.buttonTargets.forEach((element) => {
      element.checked =
        element.dataset.discoverCategoriesCategoryParam ===
        this.activeCategoryValue
    })

    this.categoryContainerTargets.forEach((element) => {
      this.setVisibilityForCategoryContainer(element)
    })

    this.cardTargets.forEach((element) => {
      this.setVisibilityForCard(element)
    })

    this.updateNoFavoritesHintVisibility()
  }

  updateNoFavoritesHintVisibility() {
    const noFavorites =
      this.element.querySelectorAll("[data-is-favorite=true]").length === 0
    const displayNoFavoritesHint =
      noFavorites && this.activeCategoryValue === "Favorites"

    this.noFavoritesHintTarget.classList.toggle(
      "hidden",
      !displayNoFavoritesHint,
    )
  }

  setVisibilityForCard(element) {
    const noCategorySelected = this.activeCategoryValue.length === 0
    const isInActiveCategory =
      element.dataset.category === this.activeCategoryValue
    const isFavorite =
      this.activeCategoryValue === "Favoriten" && element.dataset.isFavorite
    const isVisible = noCategorySelected || isInActiveCategory || isFavorite
    element.classList.toggle("hidden", !isVisible)
  }

  soundCardTargetConnected(element) {
    this.setVisibilityForSoundCard(element)
  }

  setVisibilityForCategoryContainer(element) {
    const isVisible = element.dataset.categoryName === this.activeCategoryValue
    element.classList.toggle("hidden", !isVisible)
  }

  categoryContainerTargetConnected(element) {
    this.setVisibilityForCategoryContainer(element)
  }

  reloadCards({ detail: { success } }) {
    this.cardsFrameTarget.reload()
  }

  frameRendered() {
    this.activeCategoryValueChanged()
  }

  get tabFromLocalStorage() {
    return localStorage.getItem(this.localStorageKeyValue)
  }
}
