import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

export default class extends Controller {
  connect() {
    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        this.track("color_scheme_dark")
      } else {
        this.track("color_scheme_light")
      }
    } else {
      this.track("color_scheme_undefined")
    }
  }

  track(colorScheme) {
    post(`/events?action_name=${colorScheme}`, {
      body: { details: {} },
      contentType: "application/json",
    })
  }
}
