import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    slug: String,
  }

  static classes = ["read"]

  connect() {
    const readStories = this.getReadStories()
    if (!readStories.includes(this.slugValue)) {
      this.element.classList.add(...this.readClasses)
    }
  }

  read() {
    const readStories = this.getReadStories()
    if (!readStories.includes(this.slugValue)) {
      readStories.push(this.slugValue)
      localStorage.setItem("read-stories", JSON.stringify(readStories))
    }

    this.element.classList.remove(...this.readClasses)
  }

  getReadStories() {
    const storedData = localStorage.getItem("read-stories")
    try {
      return JSON.parse(storedData) || []
    } catch {
      // Handle case where stored data is a plain string
      return storedData ? [storedData] : []
    }
  }
}
